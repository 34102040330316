import { Answer, ItemType, QuestionModel, RandomizationType } from '../../models'

export interface MatrixTableQuestionUiModel {
    id: string
    type: ItemType.MatrixTable
    question: QuestionModel
    statements: Array<Statement>
    answers: Array<Answer>
    matrixType: BipolarConfig | LikertConfig
    answerRandomization: RandomizationType
    required: boolean
}

export enum MatrixType {
    Bipolar,
    Likert
}

export interface BipolarConfig {
    type: MatrixType.Bipolar
    answerType: BipolarAnswerType
}
export enum BipolarAnswerType {
    YesNo,
    AgreeDisagree,
    TrueFalse
}

export interface LikertConfig {
    type: MatrixType.Likert
    answerType: any
}

export interface Statement {
    id: string
    text: string
    rightText: string
}
