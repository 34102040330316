import { RuleSetWithKnob } from '@feeba/types'
import { Checkbox, Divider, FormControlLabel, Stack, TextField } from '@mui/material'
import { FC, useState } from 'react'

const defaultData: RuleSetWithKnob = {
    text: 'Having trouble?'
}
export const StartWithKnobBlock: FC<Props> = ({ initValue, onUpdate }) => {
    const [data, setData] = useState(initValue)

    const handleChangeValue = (value: string) => {
        const newData = { ...data, text: value }
        setData(newData)
        onUpdate(newData)
    }

    return (
        <Stack direction="column" justifyContent="space-between" spacing={1} alignContent={'center'}>
            <Divider sx={{ bgcolor: 'black', mx: -1, my: -1 }} />
            <FormControlLabel
                label="Start the survey with a knob instead of immediate Survey Popup"
                control={
                    <Checkbox
                        checked={data != null}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            // creata a new object
                            let updatedData = null
                            if (event.target.checked) updatedData = defaultData
                            else updatedData = null
                            setData(updatedData)
                            onUpdate(updatedData)
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
            />
            {data && (
                <TextField
                    InputProps={{ inputProps: { min: 0, maxLength: 50 } }}
                    multiline={false}
                    label={'The text that is shown on the Questasdion Mark Knob'}
                    variant="outlined"
                    sx={{ minWidth: 200, bgcolor: 'white' }}
                    size="small"
                    value={data.text}
                    onChange={(event) => handleChangeValue(event.target.value)}
                />
            )}
        </Stack>
    )
}

export interface Props {
    initValue: RuleSetWithKnob | null
    onUpdate: (data: RuleSetWithKnob | null) => void
}
