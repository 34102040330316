import { SurveyModel } from '@feeba/types'
import { Box, Link } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'
import { Resizable } from 'react-resizable'
import 'react-resizable/css/styles.css'
import { PaperComponent } from '../../../../../components/DraggableDialog'

export const PreviewDialog: React.FC<Props> = ({ surveyModel, isOpen, onClose }) => {
    const [height, setHeight] = React.useState(700)
    const [width, setWidth] = React.useState(500)

    const surveyUrl = surveyModel.distribution?.anonymousLink.url
    // console.log(`PreviewDialog::surveyUrl -> ${surveyUrl}`)
    return (
        <Dialog PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" sx={{ width, height }} fullWidth maxWidth={false} open={isOpen} onClose={onClose}>
            <Resizable
                height={height}
                width={width}
                onResize={(event, data) => {
                    setHeight(data.size.height)
                    setWidth(data.size.width)
                }}
            >
                <>
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Survey Preview
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ width: '100%', height: '100%', bgcolor: 'grey' }}>
                            <iframe
                                width={'100%'}
                                height={height - 250}
                                src={surveyUrl}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                frameBorder="0"
                                allowFullScreen={true}
                            ></iframe>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Link href={surveyUrl} underline="hover" target="_blank" rel="noopener noreferrer">
                            Open in a new Tab
                        </Link>
                        <Button onClick={onClose}>Close</Button>
                    </DialogActions>
                </>
            </Resizable>
        </Dialog>
    )
}

export interface Props {
    surveyModel: SurveyModel
    isOpen: boolean
    onClose: () => void
}
