export interface SurveyPresentationModel {
    style: SurveyStyleUiModel
    modalSettings: ModalSettings
}

export interface SurveyStyleUiModel {
    primaryColor: string
    secondaryColor: string
    fontFamily: string
    foregroundContrast: string
    questionSpacing: string
    questionFontSize: string
    isQuestionBold: boolean
    answerFontSize: string
    isAnswerBold: boolean
}

export interface ModalSettings {
    modalPosition: ModalPosition
    verticalSizeInPercent: number
    horizontalSizeInPercent: number
}

export enum ModalPosition {
    TOP = 'top',
    CENTER = 'center',
    BOTTOM = 'bottom',
    FULL = 'full'
}
