// Survey Update model

import { MatrixTableQuestionUiModel } from './constructor/matrix'
import { DistributionModel } from './distribution'
import { SurveyPresentationModel } from './style'

// ------- Survey Model -------
export interface SurveyUpdateModel {
    id: string
    survey?: {
        data: SurveyModel
    }
    defaultLang?: string // ISO 639-1
}

export type KeyValueType = {
    [key: string]: string
}

export interface MessageEndModel {
    value: string
    visible: boolean
    id: string
    required: boolean
    links?: KeyValueType[]
}

export interface PrevButtonModel {
    value: string
}

export interface NextButtonModel {
    value: string
}

export interface SurveyModel {
    id: string
    name: string
    langCode: string // ISO 639-1
    items: Array<CategoryModel>
    presentation: SurveyPresentationModel
    properties: SurveyProperties
    webUrl: string
    created: Date
    updated: Date
    completed: number
    prevButtonText?: PrevButtonModel
    nextButtonText?: NextButtonModel
    distribution: DistributionModel // We use the same model
}

export interface SurveyProperties {
    typeConfig: ModalConfig | InlineConfig
}
export interface ModalConfig {
    type: SurveyType.Modal
}
export interface InlineConfig {
    type: SurveyType.Inline
    surveyFlushPeriod: number
}
export enum SurveyType {
    Modal = 'modal',
    Inline = 'inline'
}

//---- Category Model ----
export interface CategoryModel {
    id: string
    type: ItemType.Category
    name: string
    description: string
    categoryItems: Array<CategoryItem>
    messageEnd?: MessageEndModel
}

export type CategoryItem = MultipleChoiceQuestionUiModel | OptionalPageDividerUiModel | TextEntryQuestionUiModel | MatrixTableQuestionUiModel | NpsScoreQuestionUiModel

export enum ItemType {
    Category = 'category',
    MultipleChoice = 'multiple_choice',
    TextEntry = 'text_entry',
    Divider = 'divider',
    MatrixTable = 'matrix_table',
    NpsScore = 'nps_score' //question type
}

export interface QuestionModel {
    text: string
}

// ---- Concreet Question Types -------
export interface MultipleChoiceQuestionUiModel {
    id: string
    type: ItemType.MultipleChoice
    answerType: AnswerSelectionType
    question: QuestionModel
    answers: Array<Answer>
    answerRandomization: RandomizationType
    required: boolean
}

export interface TextEntryQuestionUiModel {
    id: string
    type: ItemType.TextEntry
    answerType: AnswerSelectionType
    question: QuestionModel
    answers: Array<Answer>
    required: boolean
}

export interface NpsScoreQuestionUiModel {
    id: string
    type: ItemType.NpsScore
    answerType: AnswerSelectionType
    question: QuestionModel
    answers: Array<Answer>
    required: boolean
}

export type RandomizationType = {
    type: 'no' | 'random_answers' | 'only_answers'
    randomizedSubsetSize?: number
}

export enum AnswerSelectionType {
    Single = 'single',
    Multiple = 'multiple'
}

//----
export interface OptionalPageDividerUiModel {
    type: ItemType.Divider
    isSet: boolean
}

export interface Answer {
    id: string
    text: string
    image?: { imageUrl: string } | null
    hint?: string
    type: 'predefined' | 'custom'
    count?: number | 0
    date?: Date | null
}
