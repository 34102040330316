import React, { useRef, useState } from 'react'
import { Box, Button, FormControl, FormGroup, TextField } from '@mui/material'
import { uploadFiles } from './RestClient'

export const GalleryUploader: React.FC<Props> = ({ fetchFiles }) => {
    const [fileKey, setFileKey] = useState<string | null>(null)
    const [files, setFiles] = useState<any | null>(null) //only one
    const [preview, setPreview] = useState<{ file: string } | null>(null) //only one

    const uploadInputRef = useRef<HTMLInputElement>(null)
    const inputKeyRef = useRef<HTMLInputElement>(null)

    const fileSelected = () => {
        const acceptedFiles = uploadInputRef.current
        if (!acceptedFiles?.files) {
            return false
        }
        const newFile = acceptedFiles.files[0]
        setFiles(newFile)
        console.log(URL.createObjectURL(newFile as unknown as Blob | MediaSource))
        setPreview({
            file: URL.createObjectURL(newFile as unknown as Blob | MediaSource)
        })
    }

    const fileUpload = async () => {
        const formData = new FormData()
        console.log(files)
        formData.append('files', files) //only one
        if (fileKey) {
            formData.append('key', fileKey) //only one
        }
        try {
            await uploadFiles(formData, { key: fileKey || '' })
            setFiles(null)
            setFileKey(null)
            setPreview(null)
            if (inputKeyRef.current) {
                inputKeyRef.current.value = ''
            }
            fetchFiles()
        } catch (err) {
            console.error(err)
        }
    }

    const fileSelect = () => {
        uploadInputRef.current && uploadInputRef.current.click()
    }

    const baseStyle: React.CSSProperties = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '25px 20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        cursor: 'pointer',
        verticalAlign: 'middle'
    }

    const img = {
        display: 'block',
        width: 'auto',
        maxHeight: '100%',
        maxWidth: '100%'
    }

    const boxStyle = {
        height: '200px',
        backgroundColor: '#fff',
        border: '1px dotted #cacaca',
        verticalAlign: 'baseline',
        textAlign: 'center',
        borderRadius: '16px',
        '&:hover': {
            backgroundColor: '#fff',
            opacity: [0.8, 0.7, 0.6]
        },
        marginBottom: 5,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
    return (
        <>
            {preview && preview.file ? (
                <Box sx={{ ...boxStyle }}>
                    <img
                        src={preview.file}
                        style={img}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                            URL.revokeObjectURL(preview.file)
                        }}
                    />
                </Box>
            ) : (
                <Box sx={{ ...boxStyle }}>
                    <span style={{ verticalAlign: 'bottom' }}> Preview...</span>
                </Box>
            )}
            <div onClick={() => fileSelect()} style={{ ...baseStyle }}>
                <p>Click to upload file</p>
                <input className="selectFile" type="file" ref={uploadInputRef} style={{ display: 'none' }} onChange={fileSelected} name="file" />
            </div>
            <FormControl fullWidth={true}>
                <Box sx={{ flexGrow: 1, p: 2, maxWidth: '100%', overflow: 'auto' }}>
                    <FormGroup>
                        <TextField sx={{ width: '100%' }} id={'key'} label="Unique value" variant="outlined" inputRef={inputKeyRef} onChange={(e) => setFileKey(e.target.value)} />
                    </FormGroup>
                    <FormGroup style={{ marginTop: '10px' }}>
                        <Button variant="contained" onClick={fileUpload}>
                            Upload
                        </Button>
                    </FormGroup>
                </Box>
            </FormControl>
        </>
    )
}

export interface Props {
    fetchFiles: () => {}
}
