import { FC } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { upsertSurvey } from '../../RestClient'
import { useSelector } from 'react-redux'
import { IState } from '../builder/state/hooks'
import usePopup from '../../../../popups/notifications/hook'

export const LookAndFeelTopBar: FC = ({}) => {
    const survey = useSelector((state: IState) => state.survey)
    const { showNotification } = usePopup()

    return (
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
                width: '100%',
                bgcolor: '#fff',
                //    height: '3.75em',
                borderBottom: '1px solid lightgrey',
                borderTop: '1px solid lightgrey'
            }}
        >
            <Typography alignSelf={'center'} fontFamily={'Helvetica Neue'} fontSize={'20px'} fontWeight={'400'} fontStyle={'normal'} sx={{ ml: 2 }}>
                Look and feel
            </Typography>
            <Button
                variant={'outlined'}
                sx={{ mr: 2, pr: 5, pl: 5, height: '70%' }}
                onClick={() => {
                    upsertSurvey(survey, ['items'])
                        .then(() => showNotification({ text: 'Updates saved', type: 'success' }))
                        .catch((e) => showNotification({ text: `Error: ${(e as Error).message}`, type: 'error' }))
                }}
            >
                Save
            </Button>
        </Stack>
    )
}
