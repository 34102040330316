import { SurveyModel } from '@feeba/types'
import { configureStore } from '@reduxjs/toolkit'
import { AnyAction, Middleware, Store } from 'redux'
import reducer from './reducers'
import { persistReducer, persistStore } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

const sameActionFilterMiddleware: Middleware = (store) => (next) => {
    let previousAction: AnyAction | null = null

    return (action: AnyAction) => {
        if (action === previousAction) {
            // Skip dispatching the action
            return
        }

        // Update the previousAction reference
        previousAction = action

        // Dispatch the action to the next middleware or the reducer
        return next(action)
    }
}

export const createStore = (initialValue: SurveyModel) => {
    return configureStore({
        reducer: reducer,
        preloadedState: {
            survey: initialValue,
            selection: {
                survey: initialValue
            },
            draft: false
        }
        // middleware: [sameActionFilterMiddleware],
    })
}

export const persistor = (store: Store) => persistStore(store)
