import { FC, useEffect, useState } from 'react'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { getSurveyCount } from '../../RestClient'

interface Props {
    surveyId: string
}

export const SurveySummaryPage: FC<Props> = ({ surveyId }) => {
    const [totalResponses, setTotalResponses] = useState(0)
    const [isSurveyActive, setIsSurveyActive] = useState(false)

    useEffect(() => {
        // fetch survey count
        getSurveyCount(surveyId)
            .then((e) => {
                console.log('Survey count', e)
                setTotalResponses(e.totalCount)

                if (e.totalCount > 0) {
                    setIsSurveyActive(true)
                }
            })
            .catch((ex) => {
                console.error('Error with getting surveyCount', ex)
            })
    }, [])

    return (
        <Box sx={{ m: 3 }}>
            <Grid container>
                <Grid item xs={3}>
                    <Box>
                        <Grid container alignItems={'stretch'}>
                            <Grid item xs>
                                <Box>
                                    <Typography gutterBottom sx={{ fontSize: '14px' }}>
                                        {' '}
                                        Created on 01/11/2024{' '}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Divider orientation="vertical" variant={'middle'} color={'#1876D1'} flexItem sx={{ borderWidth: 1 }} />
                            <Grid item xs>
                                <Box ml={1}>
                                    <Typography gutterBottom sx={{ fontSize: '14px' }}>
                                        {' '}
                                        Survey Summary{' '}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ p: 1, height: '100px', bgcolor: '#ffffff' }}>
                        <Typography gutterBottom variant={'h5'}>
                            {' '}
                            Did you know?{' '}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box ml={2} sx={{ p: 2, bgcolor: '#ffffff' }}>
                        <Grid container alignItems={'stretch'} mb={2}>
                            <Grid item xs>
                                <Box sx={{ height: '100%' }}>
                                    <Stack>
                                        <Typography gutterBottom> Total Responses </Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography variant={'h4'} gutterBottom>
                                            {' '}
                                            {totalResponses}{' '}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Divider orientation="vertical" color={'#1876D1'} flexItem sx={{ borderWidth: 1 }} />

                            <Grid item xs>
                                <Box ml={2}>
                                    <Stack>
                                        <Typography gutterBottom> Overall Survey Status </Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography variant={'h4'} gutterBottom sx={{ color: isSurveyActive ? 'green' : 'red' }}>
                                            {' '}
                                            {isSurveyActive ? 'OPEN' : 'CLOSED'}{' '}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Divider orientation="vertical" color={'#1876D1'} flexItem sx={{ borderWidth: 1 }} />
                            <Grid item xs>
                                <Box ml={2}>
                                    <Typography gutterBottom> Notifications </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider orientation={'horizontal'} color={'#1876D1'} variant="fullWidth" sx={{ borderWidth: 1 }} />
                        <Box mt={2}>
                            <Typography gutterBottom sx={{ fontSize: '14px' }}>
                                {' '}
                                Not getting enough responses? Unlock your custom subdomain to share as https://subdomain.example.com/make-content...{' '}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
