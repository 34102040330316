import React, { FC } from 'react'

import { Grid, TextField, Typography } from '@mui/material'
import { CategoryModel, MessageEndModel, SurveyModel } from '@feeba/types/lib'

export const MultilanguageMessageEndComponent: FC<Props> = ({ changeMessageEnd, categoryModel, categoryIndex, survey }: Props) => {
    const isEmpty = () => {
        console.log((categoryModel.messageEnd as MessageEndModel)?.value)
        const text = (categoryModel.messageEnd as MessageEndModel)?.value || null

        if (!text) {
            return true
        }
        return false
    }

    return (
        <>
            <Grid container xs={12} key={`mlmec-${categoryIndex}`} spacing={0}>
                <Grid item xs={6} style={{ background: '#fafafa', padding: '10px' }}>
                    <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 300 }}>
                        Base language
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Message end{' '}
                    </Typography>
                    <TextField
                        fullWidth
                        label="Text"
                        name="npsScore"
                        size="small"
                        variant="outlined"
                        value={categoryModel?.messageEnd?.value}
                        aria-readonly
                        style={{ marginTop: 5 }}
                    />
                </Grid>
                <Grid item xs={6} style={{ background: '#fff', padding: '10px' }}>
                    <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 300 }}>
                        Translation language{' '}
                    </Typography>

                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Message end{' '}
                    </Typography>

                    <TextField
                        color={isEmpty() ? 'warning' : 'primary'}
                        focused
                        fullWidth
                        label=""
                        name="npsScore"
                        size="small"
                        variant="outlined"
                        onChange={(event) => {
                            changeMessageEnd(event.target.value, categoryIndex)
                        }}
                        placeholder={categoryModel?.messageEnd?.value}
                        value={survey?.items[categoryIndex]?.messageEnd?.value || ''}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export interface Props {
    changeMessageEnd: (value: string, index: number) => void
    categoryModel: CategoryModel
    categoryIndex: number
    survey: SurveyModel
}
