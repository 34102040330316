import { CategoryItem, CategoryModel, ItemType, SurveyModel } from '@feeba/types'
import React from 'react'
import { SelectedComponent } from '../../../Models'
import { CategoryProperties } from './CategoryProperties'
import { EndOfSurveyPropertyComponent } from './EndOfSurveyProperties'
import { MultiChoiceProperties } from './MultiChoiceProperties'
import { TextEntryProperties } from './TextEntryProperties'
import { MatrixTableProperties } from './matrix-table/MatrixTableProperties'

export const PropertyContent = ({
    currentSelection,
    selectedComponent,
    survey
}: {
    currentSelection: CategoryItem | CategoryModel | null
    selectedComponent: SelectedComponent | null | undefined
    survey: SurveyModel
}) => {
    let content: React.ReactNode = null

    console.log(selectedComponent)

    if (!currentSelection) {
        content = null
    } else if (selectedComponent !== SelectedComponent.EndSurvey && currentSelection.type === ItemType.MultipleChoice) {
        content = <MultiChoiceProperties model={currentSelection} key={currentSelection.id} />
    } else if (selectedComponent !== SelectedComponent.EndSurvey && currentSelection.type === ItemType.Category) {
        content = <CategoryProperties model={currentSelection} key={currentSelection.id} />
    } else if (selectedComponent !== SelectedComponent.EndSurvey && currentSelection.type === ItemType.TextEntry) {
        content = <TextEntryProperties model={currentSelection} key={currentSelection.id} />
    } else if (selectedComponent !== SelectedComponent.EndSurvey && currentSelection.type === ItemType.MatrixTable) {
        content = <MatrixTableProperties model={currentSelection} />
    }

    return content
}
