import React, { FC } from 'react'

import { Grid, TextField, Typography } from '@mui/material'
import { SurveyModel } from '@feeba/types/lib'

export const MultilanguagePrevButtonComponent: FC<Props> = ({ changePrevButton, survey, defaultLanguageSurvey }) => {
    return (
        <>
            <Grid container xs={12} key={`mlqc-${'prevButton'}`} spacing={0}>
                <Grid item xs={6} style={{ background: '#fafafa', padding: '10px' }}>
                    <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 300 }}>
                        Base language
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Prev button{' '}
                    </Typography>
                    <TextField
                        fullWidth
                        label="Text"
                        name="npsScore"
                        size="small"
                        variant="outlined"
                        value={defaultLanguageSurvey.prevButtonText?.value}
                        aria-readonly
                        style={{ marginTop: 5 }}
                    />
                </Grid>
                <Grid item xs={6} style={{ background: '#fff', padding: '10px' }}>
                    <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 300 }}>
                        Translation language{' '}
                    </Typography>

                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Prev button{' '}
                    </Typography>

                    <TextField
                        color={!survey.prevButtonText?.value ? 'warning' : 'primary'}
                        focused
                        fullWidth
                        label=""
                        name="npsScore"
                        size="small"
                        variant="outlined"
                        onChange={(event) => {
                            changePrevButton(event.target.value)
                        }}
                        placeholder={defaultLanguageSurvey.prevButtonText?.value}
                        value={survey.prevButtonText?.value}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export interface Props {
    changePrevButton: (text: string) => void
    survey: SurveyModel
    defaultLanguageSurvey: SurveyModel
}
