import React, { FC, useEffect, useState } from 'react'
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material'
import { BarChart } from '@mui/x-charts/BarChart'
import { getSurveyResult, getSurveyTextResult } from '../../RestClient'
import { Answer, SurveyAnalytics, SurveyResult } from '@feeba/types'
import { AxisConfig } from '@mui/x-charts'
import { BarSeriesType } from '@mui/x-charts/models/seriesType/bar'
import { blueberryTwilightPalette, mangoFusionPalette, cheerfulFiestaPalette } from '@mui/x-charts/colorPalettes'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartOptions, Scale } from 'chart.js'
import { styled } from '@mui/material/styles'
import { TextSurveyResult } from './TextSurveyResult'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface Props {
    surveyId: string
}

const generateRandomColors = (numColors: number) => {
    const colors = []
    for (let i = 0; i < numColors; i++) {
        const red = Math.floor(Math.random() * 256)
        const green = Math.floor(Math.random() * 256)
        const blue = Math.floor(Math.random() * 256)
        colors.push(`rgba(${red}, ${green}, ${blue}, 0.8)`)
    }
    return colors
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#00B2AF',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const predefinedColors = ['rgba(255, 99, 132)', 'rgb(54, 162, 235)', 'rgba(255, 206, 86)', 'rgb(38, 173, 170)', 'rgb(255, 167, 38)'] // Add more colors as needed

const generateColors = (count: number) => {
    const colors = ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgba(255, 206, 86)', 'rgb(38, 173, 170)'] // Add more colors as needed
    let dynamicColors = []
    for (let i = 0; i < count; i++) {
        dynamicColors.push(colors[i % colors.length])
    }
    return dynamicColors
}

const splitLongText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text
    const words = text.split(' ')
    let line = ''
    let lines = []

    for (let word of words) {
        if ((line + word).length > maxLength) {
            lines.push(line.trim())
            line = ''
        }
        line += word + ' '
    }

    if (line) {
        lines.push(line.trim())
    }

    return lines.join('\n') // Joining lines with a newline character
}

export const SurveyAnalyticsResult: FC<Props> = ({ surveyId }) => {
    //undefined is equal to loading, null errror
    const [surveyResult, setSurveyResult] = React.useState<SurveyAnalytics | null | undefined>(undefined)

    const [language, setLanguage] = useState<string>('en')

    useEffect(() => {
        console.log('surveyId', surveyId)

        getSurveyResult(surveyId, language)
            .then((e) => {
                setSurveyResult(e)
            })
            .catch((ex) => {
                setSurveyResult(null)
                console.error('Error with getting surveyResults', ex)
            })
    }, [language])

    console.log('SurveyResult::surveyResult', JSON.stringify(surveyResult, null, 2))
    // console.log('SurveyResult::totalCount', totalCount)

    const convertToCSV = (results: Array<SurveyResult>) => {
        let csvStr = 'Question ID,Question Text,Answer ID,Answer Text,Answer Type,Count\n'

        results.forEach((result) => {
            result.answers.forEach((answer) => {
                let row = `"${result.question.questionId}","${result.question.text}",` + `"${answer.id}","${answer.text}","${answer.type}",${answer.count}\n`
                csvStr += row
            })
        })

        return csvStr
    }

    const downloadCSV = () => {
        if (!surveyResult || !surveyResult.results || surveyResult.results.length == 0) {
            console.error('No survey results to download')
            return
        }

        const csvString = convertToCSV(surveyResult.results)
        const blob = new Blob([csvString], { type: 'text/csv' })

        // Generate timestamp
        const timestamp = new Date().toISOString().replace(/[\-:]/g, '').replace('T', '_').split('.')[0]

        const filename = `survey-results_${timestamp}.csv`

        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = filename
        link.click()
        URL.revokeObjectURL(url)
    }

    const renderChartJS = (surveyResult: SurveyAnalytics) => {
        return surveyResult.results.map((result) => {
            const backgroundColors = result.answers.map((_, index) => predefinedColors[index % predefinedColors.length])

            const labels = result.answers.map((answer) => splitLongText(answer.text, 10)) // Adjust 25 to your preferred max length

            console.log('labels', labels)

            if (result.questionType === 'text_entry') {
                console.log('text_entry result |  totalCount ', result.totalCount, '| answer ->', result.answers)

                return (
                    <Box key={result.question.questionId} sx={{ mb: 2, border: '1px solid #ccc', p: 2 }}>
                        <Typography variant="subtitle1">{result.question.text}</Typography>
                        <TextSurveyResult surveyId={surveyId} surveyResult={result} language={language} />
                    </Box>
                )
            } else {
                const data = {
                    labels: labels,
                    datasets: [
                        {
                            label: result.question.text,
                            data: result.answers.map((answer) => answer.count),
                            backgroundColor: backgroundColors
                        }
                    ]
                }

                const options: ChartOptions<'bar'> = {
                    indexAxis: 'y',
                    scales: {
                        x: {
                            beginAtZero: true
                        },
                        y: {
                            afterFit(scale) {
                                scale.width = 250
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: true,
                            text: result.question.text
                        }
                    }
                }

                return (
                    <Box key={result.question.questionId} sx={{ my: 2, border: '1px solid #ccc', p: 2 }}>
                        <Box>
                            <Bar data={data} options={options} />
                        </Box>

                        <Box sx={{ paddingLeft: 27 }} width={1000}>
                            {renderTable(result.answers)}
                        </Box>
                    </Box>
                )
            }
        })
    }

    const renderTable = (answers: Array<Answer>) => {
        const totalCount = answers.reduce((sum, answer) => sum + (answer.count ?? 0), 0)

        return (
            <Box sx={{ my: 2, p: 2 }}>
                {/*<Typography variant="subtitle1">Survey Results</Typography>*/}
                <TableContainer component={Paper} style={{ marginBottom: 20 }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Answer Text</StyledTableCell>
                                <StyledTableCell align="right">Count</StyledTableCell>
                                <StyledTableCell align="right">Percentage</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {answers.map((answer) => (
                                <StyledTableRow key={answer.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {answer.text}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{answer.count ?? 0}</StyledTableCell>
                                    <StyledTableCell align={'right'}>{totalCount > 0 ? `${(((answer.count ?? 0) / totalCount) * 100).toFixed(2)}%` : '0.00%'}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    const renderCharts = () => {
        if (!surveyResult || !surveyResult.results || surveyResult.results.length == 0) {
            return null
        }

        // return renderMUIcharts(surveyResult)
        return renderChartJS(surveyResult)
    }

    const onLanguageChange = (event: SelectChangeEvent) => {
        setLanguage(event.target.value as string)
    }

    return (
        <Box m={5}>
            {surveyResult && (
                <>
                    <Grid container style={{ width: '100%' }}>
                        <Grid item style={{ flexGrow: 1 }}>
                            <Typography variant="h4">Survey Results</Typography>
                        </Grid>
                        <Grid item sx={{ mr: 1 }}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Language</InputLabel>
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={language} label="Language" onChange={onLanguageChange}>
                                        <MenuItem value={'en'}>English</MenuItem>
                                        <MenuItem value={'uz'}>O'zbekcha</MenuItem>
                                        <MenuItem value={'ru'}>Русский</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => downloadCSV()}>
                                Save as
                            </Button>
                        </Grid>
                    </Grid>
                    {renderCharts()}
                </>
            )}
            {surveyResult === undefined && (
                <Box sx={{ mt: 15, display: 'flex', justifyContent: 'center', height: '100vh' }}>
                    <CircularProgress />
                    <Typography variant="h6">Loading...</Typography>
                </Box>
            )}
            {surveyResult === null && (
                <Box sx={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
                    <Typography sx={{ mt: 15 }} color="red" variant="h5">
                        Something went wrong. Error fetching data.
                    </Typography>
                </Box>
            )}
        </Box>
    )
}
