import { SurveyStyleUiModel } from '@feeba/types'
import { Box, Button, FormControl, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Props } from '../../builder/SurveyBuilder'
import { ActionTypes } from '../../builder/state/actions'
import { IState, surveyDispatcher } from '../../builder/state/hooks'
import ColorSelectionButton from './ColorSelectionButton'

const fonts = ['Default', 'Arial', 'Georgia', 'Helvetica', 'Tahoma', 'Times New Roman', 'Verdana']

const fContrast = ['High', 'Medium', 'Low']

const qSPacing = ['Comfortable', 'Extended', 'Compact']

export const Style: FC<Props> = () => {
    const dispatch = surveyDispatcher()
    const surveyToEdit = useSelector((state: IState) => state.survey)

    const updateGlobalSurveyInstance = (surveyStyle: SurveyStyleUiModel) => {
        // console.log("Style::dispatching survey update")
        dispatch({
            type: ActionTypes.SURVEY_BODY_UPDATE,
            payload: {
                survey: {
                    ...surveyToEdit,
                    presentation: {
                        ...surveyToEdit?.presentation,
                        style: surveyStyle
                    }
                }
            }
        })
    }

    // console.log("Style::surveyToEdit?.presentation?.general", JSON.stringify(surveyToEdit?.presentation?.general, null, 2))
    const surveyStyle = surveyToEdit?.presentation?.style || {
        primaryColor: '#309DAB',
        secondaryColor: '#309DAB',
        fontFamily: fonts[0],
        foregroundContrast: fContrast[1].toLowerCase(),
        questionSpacing: qSPacing[0],
        questionFontSize: '20px',
        answerFontSize: '16px',
        isAnswerBold: false,
        isQuestionBold: false
    }

    const qText = parseInt(surveyStyle?.questionFontSize as string, 10)
    const [questionText, setQuestionText] = useState(qText || 20)
    const [answerText, setAnswerText] = useState(16)

    // console.log("Style::surveyStyle", JSON.stringify(surveyStyle, null, 2))
    return (
        <Stack sx={{ width: 180, position: 'relative' }}>
            <Stack direction={'column'} sx={{ display: 'flex', height: '100%', ml: 2 }}>
                <Typography align={'center'}>Style</Typography>
                <ColorSelectionButton
                    color="primary"
                    defaultColor={surveyStyle.primaryColor}
                    onColorChange={(color) => updateGlobalSurveyInstance({ ...surveyStyle, primaryColor: color })}
                />
                <ColorSelectionButton
                    color="secondary"
                    defaultColor={surveyStyle.secondaryColor}
                    onColorChange={(color) => updateGlobalSurveyInstance({ ...surveyStyle, secondaryColor: color })}
                />

                {/*Font selection*/}
                <Stack direction={'column'} sx={{ display: 'flex', height: '100%', my: 1 }}>
                    <Typography align={'left'}>Font</Typography>
                    <FormControl fullWidth>
                        <Select
                            id="font-select"
                            defaultValue={fonts[0]}
                            value={surveyStyle.fontFamily}
                            onChange={(event: SelectChangeEvent) => {
                                // console.log("Style::font change", { ...surveyStyle, fontFamily: event.target.value as string })
                                updateGlobalSurveyInstance({ ...surveyStyle, fontFamily: event.target.value as string })
                            }}
                        >
                            {fonts.map((font, i) => (
                                <MenuItem key={i} value={font}>
                                    <Typography fontFamily={fonts[i] === 'Default' ? 'Helvetica' : fonts[i]}>{font}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={'column'} sx={{ display: 'flex', height: '100%', my: 1 }}>
                    <Typography align={'left'}>Foreground Contrast</Typography>
                    <FormControl fullWidth>
                        <Select
                            id="foregrount-select"
                            defaultValue={fContrast[1]}
                            value={surveyStyle.foregroundContrast}
                            onChange={(event: SelectChangeEvent) =>
                                updateGlobalSurveyInstance({
                                    ...surveyStyle,
                                    foregroundContrast: event.target.value as string
                                })
                            }
                        >
                            {fContrast.map((f, i) => (
                                <MenuItem key={i} value={f}>
                                    <Typography>{f}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={'column'} sx={{ display: 'flex', height: '100%', my: 1 }}>
                    <Typography align={'left'}>Question Spacing</Typography>
                    <FormControl fullWidth>
                        <Select
                            id="questionSpacing-select"
                            defaultValue={qSPacing[0]}
                            value={surveyStyle.questionSpacing}
                            onChange={(event: SelectChangeEvent) => updateGlobalSurveyInstance({ ...surveyStyle, questionSpacing: event.target.value as string })}
                        >
                            {qSPacing.map((q, i) => (
                                <MenuItem key={i} value={q}>
                                    <Typography>{q}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>

                <Stack direction={'column'} sx={{ display: 'flex', height: '100%', my: 1 }}>
                    <Typography align={'left'}>Question Text (px)</Typography>
                    <Box display="flex" flexDirection="row">
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setQuestionText(questionText + 1)
                                updateGlobalSurveyInstance({ ...surveyStyle, questionFontSize: questionText + 1 + 'px' })
                            }}
                        >
                            +
                        </Button>
                        <Box mx={1}>
                            <TextField
                                variant="outlined"
                                id="question-text"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                value={questionText}
                                onChange={(e) => setQuestionText(parseInt(e.target.value, 10))}
                                sx={{ width: '50px' }}
                            />
                        </Box>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (questionText > 0) {
                                    setQuestionText(questionText - 1)
                                    updateGlobalSurveyInstance({ ...surveyStyle, questionFontSize: questionText - 1 + 'px' })
                                }
                            }}
                        >
                            -
                        </Button>
                        <Button
                            variant={surveyStyle.isQuestionBold ? 'contained' : 'outlined'}
                            sx={{ mx: 1 }}
                            onClick={() => updateGlobalSurveyInstance({ ...surveyStyle, isQuestionBold: !surveyStyle.isQuestionBold })}
                        >
                            B
                        </Button>
                    </Box>
                </Stack>

                <Stack direction={'column'} sx={{ display: 'flex', height: '100%', my: 1 }}>
                    <Typography align={'left'}>Answer Text (px)</Typography>
                    <Box display="flex" flexDirection="row">
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setAnswerText(answerText + 1)
                                updateGlobalSurveyInstance({ ...surveyStyle, answerFontSize: answerText + 1 + 'px' })
                            }}
                        >
                            +
                        </Button>
                        <Box mx={1}>
                            <TextField
                                variant="outlined"
                                id="answer-text"
                                value={answerText}
                                sx={{ width: '50px' }}
                                onChange={(e) => setAnswerText(parseInt(e.target.value, 10))}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                        </Box>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                if (answerText > 0) {
                                    setAnswerText(answerText - 1)
                                    updateGlobalSurveyInstance({ ...surveyStyle, answerFontSize: answerText - 1 + 'px' })
                                }
                            }}
                        >
                            -
                        </Button>
                        <Button
                            variant={surveyStyle.isAnswerBold ? 'contained' : 'outlined'}
                            sx={{ mx: 1 }}
                            onClick={() => updateGlobalSurveyInstance({ ...surveyStyle, isAnswerBold: !surveyStyle.isAnswerBold })}
                        >
                            B
                        </Button>
                    </Box>
                </Stack>
            </Stack>
            <Stack direction={'row'} sx={{ display: 'flex', height: '100%' }}></Stack>
        </Stack>
    )
}
