import React, { FC } from 'react'

import { Divider, Grid, TextField, Typography } from '@mui/material'
import { NpsScoreQuestionUiModel } from '@feeba/types/lib'

export const MultilanguageNpsScoreComponent: FC<Props> = ({ changeNpsScoretBySelectedLanguage, categoryItem, survey, categoryIndex, index }) => {
    const isEmpty = () => {
        console.log((survey?.items[index]?.categoryItems[categoryIndex] as NpsScoreQuestionUiModel)?.question.text)
        const text = (survey?.items[index]?.categoryItems[categoryIndex] as NpsScoreQuestionUiModel)?.question.text || null

        if (!text) {
            return true
        }
        return false
    }

    return (
        <>
            <Grid container xs={12} key={`mlqc-${categoryIndex}`} spacing={0}>
                <Grid item xs={6} style={{ background: '#fafafa', padding: '10px' }}>
                    <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 300 }}>
                        Base language
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        NPS Score{' '}
                    </Typography>
                    <TextField fullWidth label="Text" name="npsScore" size="small" variant="outlined" value={categoryItem.question.text} aria-readonly style={{ marginTop: 5 }} />
                </Grid>
                <Grid item xs={6} style={{ background: '#fff', padding: '10px' }}>
                    <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: 300 }}>
                        Translation language{' '}
                    </Typography>

                    <Typography variant="body1" style={{ marginBottom: '10px' }}>
                        Text enty{' '}
                    </Typography>

                    <TextField
                        color={isEmpty() ? 'warning' : 'primary'}
                        focused
                        fullWidth
                        label=""
                        name="npsScore"
                        size="small"
                        variant="outlined"
                        onChange={(event) => {
                            changeNpsScoretBySelectedLanguage(event.target.value, index, categoryIndex)
                        }}
                        placeholder={categoryItem.question.text}
                        value={(survey?.items[index]?.categoryItems[categoryIndex] as NpsScoreQuestionUiModel)?.question.text || ''}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export interface Props {
    changeNpsScoretBySelectedLanguage: (value: string, index: number, categoryIndex: number) => void
    categoryItem: NpsScoreQuestionUiModel
    survey: any
    categoryIndex: number
    index: number
}
