import React, { FC, useState } from 'react'
import {
    Grid,
    Typography,
    Dialog,
    Stack,
    Box,
    DialogTitle,
    Divider,
    DialogContent,
    Button,
    List,
    ListItem,
    IconButton,
    ListItemButton,
    ListItemIcon,
    Checkbox,
    ListItemText,
    Paper,
    InputBase,
    DialogActions
} from '@mui/material'

import { SurveyLanguageUiModel } from '../../../Models'
import { LANGUAGE_LIST } from '../../../../../../utils/Langs'
import SearchIcon from '@mui/icons-material/Search'

export const SurveyAddLanguageDialog: FC<Props> = ({ setCreateLanguageDialogOpen, createLanguageDialogOpen, createLanguageAction, languages }) => {
    const [checked, setChecked] = useState<string[]>([])
    const [filter, setFilter] = useState<string | null>(null)

    const handleToggle = (value: string) => () => {
        const oldLanguage = languages?.find((el) => el.langCode === value)
        if (oldLanguage) {
            return
        }

        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]
        console.log(value)
        console.log(currentIndex)
        console.log(newChecked)
        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    const cancelAction = () => {
        setChecked([])
        setFilter(null)
        setCreateLanguageDialogOpen(false)
    }

    const createLanguageActionMap = () => {
        const addedLanguage = checked.map((lang) => {
            const langFromList = LANGUAGE_LIST.find((el) => el.code === lang)
            if (!langFromList) {
                throw new Error('Language not found')
            }
            return {
                langCode: langFromList?.code,
                label: langFromList?.name,
                visibleToRespondents: true,
                translationProgress: 0,
                isDefault: false
            }
        })

        createLanguageAction(addedLanguage)
        setChecked([])
        setFilter(null)
        setCreateLanguageDialogOpen(false)
    }

    return (
        <>
            <Dialog
                onClose={(event, reason) => setCreateLanguageDialogOpen(false)}
                open={createLanguageDialogOpen}
                style={{ height: '100%' }}
                PaperProps={{
                    style: {
                        height: '80vh',
                        overflow: 'hidden'
                    }
                }}
            >
                <DialogTitle id="add-language-dialog-title">{'Add language'}</DialogTitle>
                <Divider />
                <DialogContent style={{ overflow: 'hidden' }}>
                    <Stack direction="column" sx={{ m: 1 }}>
                        <Box
                            sx={{
                                flexGrow: 1,
                                p: 2,
                                width: '100%',
                                alignItems: 'stretch'
                            }}
                        >
                            <Typography variant="h2" fontSize={16} marginBottom={1}>
                                Select one or more languages from the list below or create a new language.
                            </Typography>

                            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop: 2 }}>
                                <Paper
                                    component="form"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        boxShadow: 0,
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        borderRadius: 1
                                    }}
                                >
                                    <IconButton sx={{ p: '10px' }} aria-label="search-language">
                                        <SearchIcon />
                                    </IconButton>
                                    <InputBase placeholder="Search" inputProps={{ 'aria-label': 'search-language' }} onChange={(e) => setFilter(e.target.value)} />
                                </Paper>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'stretch', height: '100%' }}>
                                <List
                                    sx={{
                                        marginTop: 2,
                                        width: '100%',
                                        maxWidth: '100%',
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 'calc(100vh - 450px)',
                                        '& ul': { padding: 0 }
                                    }}
                                >
                                    <Grid xs={12} item></Grid>
                                    {LANGUAGE_LIST.filter((lang) => {
                                        if (filter) {
                                            return lang.name.toLowerCase().includes(filter.toLowerCase())
                                        } else {
                                            return true
                                        }
                                    }).map((lang) => (
                                        <ListItem key={lang.code}>
                                            <ListItemButton onClick={handleToggle(lang.code)} dense>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={
                                                            checked.indexOf(lang.code as string) !== -1 ||
                                                            (languages && languages.find((el) => el.langCode === lang.code) ? true : false)
                                                        }
                                                        tabIndex={-1}
                                                        disabled={languages && languages.find((el) => el.langCode === lang.code) ? true : false}
                                                        inputProps={{ 'aria-labelledby': lang.code }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText style={{ textAlign: 'left', width: '50%' }} id={`lang-name-${lang.code}`} primary={lang.name} />
                                                <ListItemText style={{ textAlign: 'left' }} id={`lang-code-${lang.code}`} primary={String(lang.code).toUpperCase()} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                    </Stack>
                </DialogContent>
                <Divider />

                <DialogActions
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 30
                    }}
                >
                    <Button variant="text" color="info" fullWidth={false} onClick={() => cancelAction()}>
                        Cancel
                    </Button>

                    <Button variant="contained" color="primary" fullWidth={false} onClick={() => createLanguageActionMap()} disabled={checked.length === 0}>
                        Add Language
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export interface Props {
    setCreateLanguageDialogOpen: (open: boolean) => void
    createLanguageDialogOpen: boolean
    createLanguageAction: (language: SurveyLanguageUiModel[]) => void
    languages: SurveyLanguageUiModel[] | null
}
