import { DisplaySettings, FormatPaint, Foundation, RequestQuote, Schema, SvgIconComponent, Translate } from '@mui/icons-material'
import { Box, CircularProgress, Divider, Stack, Tab, Tabs, Tooltip } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Store } from 'redux'
import usePopup from '../../../../popups/notifications/hook'
import { fetchSurvey } from '../../RestClient'
import { LookAndFeelPage } from '../lookandfeel/LookAndFeelPage'
import { SurveyBuilder } from './SurveyBuilder'
import { SurveyMultilanguagePage } from './SurveyMultilanguagePage'
import { addInitialCategoryBlockIfEmpty } from './Utils'
import { createStore } from './state/store'

export interface TabValue {
    label: string
    path: string
    icon: SvgIconComponent
    page: ((surveyId: string) => JSX.Element) | null
}

const tabs: TabValue[] = [
    { label: 'Buider', path: 'structure', icon: Foundation, page: (surveyId) => <SurveyBuilder /> },
    { label: 'Survey Flow', path: 'survey_flow', icon: Schema, page: null },
    { label: 'Look & Feel', path: 'look_n_feel', icon: FormatPaint, page: (surveyId) => <LookAndFeelPage /> },
    { label: 'Survey Options', path: 'survey_options', icon: DisplaySettings, page: null },
    { label: 'Quotes', path: 'quotes', icon: RequestQuote, page: null },
    {
        label: 'Translation',
        path: 'translation',
        icon: Translate,
        page: (surveyId) => <SurveyMultilanguagePage surveyId={surveyId} />
    }
]

export const SurveyBuilderRouterPage: FC<{ surveyId: string }> = ({ surveyId }) => {
    const location = useLocation()
    const { showNotification } = usePopup()
    // const [surveyToEdit, setSurveyToEdit] = useState<SurveyModel | null>(null)
    const [storeState, setStoreState] = useState<Store | null>(null)

    const navigate = useNavigate()
    const findCurrentTab = () => {
        const path = location.pathname.split('/')[4]
        // Check if any of the tab Values is in the pathname, if yes return the index of that tab, else return 0
        const index = tabs.findIndex((tab) => path === tab.path)

        return index === -1 ? 0 : index
    }
    const [tabIndex, setTabIndex] = useState(findCurrentTab)

    useEffect(() => {
        const tabIndex = findCurrentTab()
        setTabIndex(tabIndex)
    }, [location.pathname])

    useEffect(() => {
        fetchSurvey(surveyId)
            .then((result) => {
                // console.log('SurveyBuilderRouterPage::fetchSurvey::result', result)
                setStoreState(createStore(addInitialCategoryBlockIfEmpty(result)))
                // console.log('SurveyBuilderRouterPage::fetchSurvey::store::set')
            })
            .catch((err) => {
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })

        return () => {
            setStoreState(null)
        }
    }, [surveyId])

    return storeState ? (
        <Routes>
            <Route
                element={
                    <Provider store={storeState}>
                        <Stack direction="row">
                            <Tabs
                                sx={{ backgroundColor: 'white', height: '100vh' }}
                                TabIndicatorProps={{ style: { width: '.5rem' } }}
                                textColor="inherit"
                                indicatorColor="primary"
                                value={tabIndex}
                                orientation="vertical"
                                onChange={(event, newValue) => {
                                    setTabIndex(newValue)
                                    navigate(tabs[newValue]?.path)
                                }}
                            >
                                {tabs.map((tab, index) => (
                                    <Tooltip key={index} title={tab.label} arrow placement="right">
                                        <Tab disabled={!tab.page} sx={{ minWidth: 'auto' }} icon={<tab.icon fontSize="small" />} />
                                    </Tooltip>
                                ))}
                            </Tabs>
                            <Divider orientation="vertical" flexItem />
                            <Outlet />
                        </Stack>
                    </Provider>
                }
            >
                {tabs.map((tab, index) => (
                    <Route key={index} path={tab.path + '/*'} element={tab.page ? tab.page(surveyId) : <div>{tab.label} - Page not found</div>} />
                ))}
                <Route path="*" element={<Navigate to={tabs[0].path} />} />
                <Route index element={<Navigate to={tabs[0].path} />} />
            </Route>
        </Routes>
    ) : (
        <Box>
            {' '}
            <CircularProgress />{' '}
        </Box>
    )
}
