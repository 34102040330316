import { FC, SyntheticEvent, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { SurveyAnalyticsResult } from './SurveyResult'
import { SurveyTable } from './SurveyTable'

interface Props {
    surveyId: string
}

export const SurveyResultParent: FC<Props> = ({ surveyId }) => {
    const [value, setValue] = useState(0)
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const renderComponent = () => {
        switch (value) {
            case 0:
                return <SurveyAnalyticsResult surveyId={surveyId} />
            case 1:
                return <SurveyTable surveyId={surveyId} />
            default:
                return <SurveyAnalyticsResult surveyId={surveyId} /> // Default case
        }
    }

    return (
        <Box sx={{ m: 1, width: '100%', bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Diagrams" />
                <Tab label="Table" />
            </Tabs>

            {renderComponent()}
        </Box>
    )
}
