import { MoreHoriz } from '@mui/icons-material'
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fade,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button
} from '@mui/material'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import { FC, useState } from 'react'
import { SurveyLanguageUiModel } from '../../../Models'

export const SurveyGeneralList: FC<Props> = ({ languages, choseLanguage, deleteSurveyLanguageAction }) => {
    const [kebabMenuState, setKebabMenuState] = useState<{
        index: number
        anchorEl: HTMLElement
    } | null>(null)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [deleteLanguage, setDeleteLanguage] = useState<string | null>(null)
    const handleClose = () => setKebabMenuState(null)

    const handleChoseLanguage = (language: string) => {
        handleClose()
        choseLanguage(language)
    }

    const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        )
    }

    const tableTextStyle = {
        fontFamily: 'Roboto',
        fontWeight: '300'
    }

    const preRemoveSurveyLanguage = (language: string) => {
        setOpenDeleteDialog(true)
        setDeleteLanguage(language)
    }

    const deleteSurveyLanguageActionInList = () => {
        if (!deleteLanguage) {
            setOpenDeleteDialog(false)
            setDeleteLanguage(null)
            return
        }
        deleteSurveyLanguageAction(deleteLanguage)

        setOpenDeleteDialog(false)
        setDeleteLanguage(null)
    }

    return (
        <>
            <Dialog
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false)
                    setDeleteLanguage(null)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Removing localization'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure you want to remove the current localization: {deleteLanguage}?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenDeleteDialog(false)
                            setDeleteLanguage(null)
                        }}
                    >
                        Disagree
                    </Button>
                    <Button onClick={() => deleteSurveyLanguageActionInList()} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>

            <Typography variant="h2" fontSize={16} marginBottom={1}>
                Translations overview
            </Typography>

            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={tableTextStyle}>Language</TableCell>
                            <TableCell style={tableTextStyle} align="center">
                                Code
                            </TableCell>
                            <TableCell style={tableTextStyle} align="center">
                                Visible to respondents
                            </TableCell>
                            <TableCell style={tableTextStyle} align="left">
                                Translation progress
                            </TableCell>
                            <TableCell style={tableTextStyle} align="right"></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody
                        style={{
                            fontFamily: 'Roboto',
                            fontWeight: '300 !important',
                            fontSize: '16px'
                        }}
                    >
                        {languages.map((language, index) => (
                            <TableRow key={index}>
                                <TableCell style={tableTextStyle} component="th" scope="row">
                                    {language.label}
                                </TableCell>
                                <TableCell style={tableTextStyle} align="center">
                                    {language.langCode}
                                </TableCell>
                                <TableCell style={tableTextStyle} align="center">
                                    {language.visibleToRespondents ? 'Yes' : 'No'}
                                </TableCell>
                                <TableCell style={tableTextStyle} align="center">
                                    <LinearProgressWithLabel value={language.translationProgress || 0} />
                                </TableCell>
                                <TableCell style={tableTextStyle} align="right">
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-haspopup="true"
                                        aria-controls={kebabMenuState?.index === index ? 'long-menu' : undefined}
                                        aria-expanded={kebabMenuState?.index === index ? 'true' : undefined}
                                        onClick={(event) =>
                                            setKebabMenuState({
                                                index,
                                                anchorEl: event.currentTarget
                                            })
                                        }
                                    >
                                        <MoreHoriz />
                                    </IconButton>
                                    <Menu
                                        id="fade-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'fade-button'
                                        }}
                                        anchorEl={kebabMenuState?.anchorEl}
                                        open={kebabMenuState?.index === index ? true : false}
                                        onClose={handleClose}
                                        TransitionComponent={Fade}
                                    >
                                        <MenuItem style={tableTextStyle} onClick={() => handleChoseLanguage(language.langCode)}>
                                            Edit translation
                                        </MenuItem>
                                        {!language.isDefault && (
                                            <MenuItem style={tableTextStyle} onClick={() => preRemoveSurveyLanguage(language.langCode)}>
                                                Delete translation
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export interface Props {
    languages: SurveyLanguageUiModel[]
    choseLanguage: (language: string) => void
    deleteSurveyLanguageAction: (language: string) => void
}
