import { Visibility, VisibilityOff } from '@mui/icons-material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { createTheme } from '@mui/material/styles'
import { Form, Formik, FormikHelpers } from 'formik'
import * as React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { login } from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'
import { AUTH_TOKEN } from '../../../../setup/axios/SetupAxios'

export function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" to="https://feeba.io">
                feeba.io
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

export function Login() {
    const [showPassword, setShowPassword] = React.useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show)
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()

    const dispatch = useDispatch()

    const onSubmit = (values: any, formikHelpers: FormikHelpers<ValueSchema>) => {
        login(values.email, values.password)
            .then(({ data: { jwt } }) => {
                console.log('Setting token', jwt)
                localStorage.setItem(AUTH_TOKEN, jwt as string)
                dispatch(auth.actions.login(jwt))
            })
            .catch((err) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setStatus({ error: { message: `Error: ${err.message}` } })
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <Formik sx={{ mt: 1 }} initialValues={initialValues} validationSchema={loginSchema} onSubmit={onSubmit}>
                {({ values, touched, errors, handleChange, handleBlur, isValid, dirty, status }) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ height: '6rem', width: '100%' }}>
                            {status && status.error && (
                                <Typography
                                    sx={{
                                        height: '4rem',
                                        border: '2px solid red',
                                        backgroundColor: 'pink',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    {status.error.message}
                                </Typography>
                            )}
                        </Box>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in to Feeba.io
                        </Typography>
                        <Form>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                            />
                            <FormControl margin="normal" fullWidth variant="outlined">
                                <InputLabel error={touched.password && Boolean(errors.password)} htmlFor="outlined-adornment-password">
                                    Password
                                </InputLabel>
                                <OutlinedInput
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    error={touched.password && Boolean(errors.password)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                                {touched.password && errors.password && (
                                    <FormHelperText error id="username-error">
                                        {errors.password}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                {' '}
                                Sign In{' '}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant="body2" color="text.secondary">
                                        <Link to="/auth/forgot-password">Forgot password?</Link>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" color="text.secondary">
                                        <Link to="/auth/registration">{"Don't have an account? Sign Up"}</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Form>
                        <Copyright sx={{ mt: 8, mb: 4 }} />
                    </Box>
                )}
            </Formik>
        </Container>
    )
}

const loginSchema = Yup.object().shape({
    email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
    password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required')
})

const initialValues: ValueSchema = {
    email: '',
    password: ''
}
interface ValueSchema {
    email: string
    password: string
}
