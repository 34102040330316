import React, { FC } from 'react'

import { Divider, MenuItem, MenuList, Typography, Button } from '@mui/material'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { SurveyLanguageUiModel } from '../../../Models'
import AddIcon from '@mui/icons-material/Add'

export const SurveyMultilanguageMenuComponent: FC<Props> = ({ languages, choseLanguage, setCreateLanguageDialogOpen }) => {
    return (
        <>
            <MenuList>
                <Typography variant="h2" component="h2" fontSize={16} margin={'10px 0 10px 0'} padding={'0 15px 0 15px '}>
                    Languages
                </Typography>
                <Divider />

                <MenuItem>
                    <Button variant="outlined" fullWidth={true} onClick={() => setCreateLanguageDialogOpen(true)}>
                        <AddIcon
                            sx={{
                                marginRight: 1
                            }}
                        />
                        Add Language
                    </Button>
                </MenuItem>
                <Divider />

                {languages &&
                    languages?.map((el, index) => {
                        return (
                            <MenuItem
                                key={index}
                                onClick={() => choseLanguage(el.langCode)}
                                style={{
                                    width: '100%',
                                    padding: '15px',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography variant="h2" component="h2" fontSize={16} textAlign={'left'} style={{ display: 'inline-block' }}>
                                    {el.label} ({el.langCode}){' '}
                                </Typography>
                                <ArrowForwardIosIcon fontSize="small" style={{ display: 'inline-block' }} />
                            </MenuItem>
                        )
                    })}
            </MenuList>
        </>
    )
}

export interface Props {
    languages: SurveyLanguageUiModel[]
    setCreateLanguageDialogOpen: (open: boolean) => void
    choseLanguage: (language: string) => void
}
