import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { Box } from '@mui/material'
import { AppBoostrap } from './modules/auth'

const App = () => {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <LayoutProvider>
                <AppBoostrap>
                    <Outlet />
                </AppBoostrap>
            </LayoutProvider>
        </Suspense>
    )
}

export { App }
