import { DefaultTriggerDistributionConfig, RepeatType, ScheduleConfig, StopShowingType } from '@feeba/types'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { DateTime } from 'luxon'
import { UpgradeTooltip } from '../../../../../../components/FeebaToolTip'
import { Lock, LockOpen } from '@mui/icons-material'

export const ScheduleSectionComponent: FC<Props> = ({ initialConfig, onUpdate }) => {
    const [data, setData] = React.useState<ScheduleConfig>(
        initialConfig || {
            showConfig: { time: new Date() },
            stopConfig: { selection: StopShowingType.SHOW_FOREVER },
            repeat: { selection: RepeatType.EVERY_TIME }
        }
    )

    useEffect(() => onUpdate(data), [data])

    return (
        <>
            <FormControl fullWidth>
                <FormLabel>Start Showing</FormLabel>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DateTimePicker
                        sx={{ width: 230 }}
                        value={data.showConfig.time && DateTime.fromJSDate(data.showConfig.time)}
                        onChange={(newValue) => {
                            newValue && setData({ ...data, showConfig: { time: newValue.toJSDate() } })
                        }}
                    />
                </LocalizationProvider>
            </FormControl>

            <FormControl sx={{ pt: 2 }} fullWidth>
                <FormLabel>Stop Showing</FormLabel>
                <RadioGroup
                    name="radio-buttons-group"
                    value={data.stopConfig.selection}
                    onChange={(event) => setData({ ...data, stopConfig: { selection: event.target.value as StopShowingType } })}
                >
                    <FormControlLabel value={StopShowingType.SHOW_FOREVER} control={<Radio size="small" />} label="Show forever" />
                    <FormControlLabel value={StopShowingType.SHOW_UNTIL} control={<Radio size="small" />} label="Specific time" />
                    {data.stopConfig.selection === StopShowingType.SHOW_UNTIL && (
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateTimePicker
                                sx={{ width: 230 }}
                                value={(data.stopConfig.time && DateTime.fromJSDate(data.stopConfig.time)) || DateTime.fromJSDate(data.showConfig.time).plus({ month: 1 })}
                                onChange={(newValue) => {
                                    newValue && setData({ ...data, stopConfig: { ...data.stopConfig, time: newValue.toJSDate() } })
                                }}
                            />
                        </LocalizationProvider>
                    )}
                </RadioGroup>
            </FormControl>

            <FormControl sx={{ pt: 2 }} fullWidth>
                <FormLabel>How often do you want to show this message?</FormLabel>
                <RadioGroup
                    name="radio-buttons-group"
                    value={data.repeat.selection}
                    onChange={(event) => setData({ ...data, repeat: { selection: event.target.value as RepeatType } })}
                >
                    <FormControlLabel value={RepeatType.ONLY_ONCE} control={<Radio size="small" />} label="Only once" />
                    <FormControlLabel value={RepeatType.EVERY_TIME} control={<Radio size="small" />} label="Every time trigger conditions are satisfied time" />
                    <Box flexDirection={'row'}>
                        <FormControlLabel sx={{ flexGrow: 0 }} disabled value={RepeatType.MULTIPLE_TIMES} control={<Radio size="small" />} label="Multiple times" />
                        <UpgradeTooltip placement="top" origin="schedule#multiple_time_repeat">
                            <LockOpen fontSize="small" sx={{ color: 'gray' }} />
                        </UpgradeTooltip>
                    </Box>
                </RadioGroup>
            </FormControl>
        </>
    )
}

interface Props {
    initialConfig: ScheduleConfig
    onUpdate: (config: ScheduleConfig) => void
}
