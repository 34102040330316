import { Add, Remove } from '@mui/icons-material'
import { Box, BoxProps, IconButton } from '@mui/material'
import { FC, useState } from 'react'

const iconCommonSx = {
    height: 20,
    width: 20,
    bgcolor: {
        backgroundColor: '#2f71f5',
        '&:hover': { backgroundColor: '#0022df' }
    }
}

export const SelectableItem: FC<Props & BoxProps> = ({ children, onSelected, isSelected, showBorder, sx, showAddButtons, onAddAbove, onAddBelow, showRemoveButtons, onRemove }) => {
    const [hovering, setHovering] = useState(false)
    const borderThickness = showBorder ? '1px' : '0px'

    let boxSX: any = {
        '&:hover': {
            border: `${borderThickness} solid #000`,
            borderRadius: 2,
            boxShadow: showBorder ? '1px 1px 4px 1px #ddd' : undefined
        },
        '&': {
            border: `${borderThickness} solid #ccc`,
            borderRadius: 2
        },
        zIndex: 201,
        position: 'relative',
        ...sx
    }
    if (isSelected) {
        boxSX = {
            '&:hover': {
                border: '1px solid #00f',
                borderRadius: 2,
                boxShadow: '1px 1px 4px 1px #ddd'
            },
            '&': {
                border: '1px solid #00f',
                borderRadius: 2,
                boxShadow: '1px 1px 4px 1px #ddd'
            },
            position: 'relative',
            zIndex: 201,
            ...sx
        }
    }
    return (
        <Box
            sx={boxSX}
            onClick={(event) => {
                onSelected()
                event.stopPropagation()
            }}
            onMouseOver={(e) => {
                setHovering(true)
                e.stopPropagation()
            }}
            onMouseOut={(e) => {
                setHovering(false)
                e.stopPropagation()
            }}
        >
            {children}
            {hovering && showAddButtons && (
                <IconButton
                    sx={{ position: 'absolute', left: -10, top: -10, ...iconCommonSx }}
                    size="small"
                    onClick={() => {
                        onAddAbove?.()
                    }}
                >
                    <Add fontSize="inherit" sx={{ color: 'white' }} />
                </IconButton>
            )}
            {hovering && showRemoveButtons && (
                <IconButton
                    sx={{
                        position: 'absolute',
                        left: -10,
                        top: '50%',
                        mt: '-10px',
                        height: '20px',
                        width: 20,
                        border: '1px solid #f00',
                        bgcolor: {
                            backgroundColor: '#fff',
                            '&:hover': { backgroundColor: '#fff' }
                        }
                    }}
                    size="small"
                    onClick={onRemove}
                >
                    <Remove fontSize="inherit" sx={{ color: '#f00' }} />
                </IconButton>
            )}
            {hovering && showAddButtons && (
                <IconButton
                    sx={{ position: 'absolute', left: -10, bottom: -10, ...iconCommonSx }}
                    size="small"
                    onClick={() => {
                        onAddBelow?.()
                    }}
                >
                    <Add fontSize="inherit" sx={{ color: 'white' }} />
                </IconButton>
            )}
        </Box>
    )
}

export interface Props {
    onSelected: () => void
    isSelected: boolean
    showAddButtons: boolean
    onAddAbove?: () => void
    onAddBelow?: () => void
    showRemoveButtons: boolean
    onRemove?: () => void
    showBorder: boolean
    children?: React.ReactNode
}
