import React, { FC, useState } from 'react'

import { BoxProps, FormControl, IconContainerProps, Rating, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FramelessEdit } from './FramelessEdit'
import { ActionTypes } from '../builder/state/actions'
import { TextField } from '@mui/material'
import { IState, surveyDispatcher, useCurrentSelection } from '../builder/state/hooks'
import { ItemType, NpsScoreQuestionUiModel } from '@feeba/types'
import { SelectableItem } from '../builder/component/SelectableItem'

export const NpsScoreComponent: FC<Props & BoxProps> = ({ data, sx, questionIndex, categoryId, index }) => {
    const dispatch = surveyDispatcher()
    const selectedItem = useCurrentSelection((state: IState) => {
        if (state.selection?.categoryItem?.type !== ItemType.Divider && state.selection?.categoryItem?.id === data.id) return state.selection?.categoryItem
        else return null
    })

    const updateNpsScoreText = (str: string) => {
        if (str !== data.question.text) {
            dispatch({
                type: ActionTypes.NPS_SCORE_UPDATE,
                payload: {
                    categoryItem: {
                        ...data,
                        question: { ...data.question, text: str }
                    }
                }
            })
        }
    }

    // console.log('MultiChoiceComponent::Selected ', data.id, selectedItem?.id)
    return (
        <SelectableItem
            showAddButtons
            showRemoveButtons
            showBorder
            sx={sx}
            isSelected={data.id === selectedItem?.id}
            onAddAbove={() =>
                dispatch({
                    type: ActionTypes.ADD_QUESTION_ABOVE,
                    payload: {
                        currentQuestionIndex: questionIndex,
                        currentCategoryId: categoryId,
                        questionType: ItemType.NpsScore
                    }
                })
            }
            onAddBelow={() =>
                dispatch({
                    type: ActionTypes.ADD_QUESTION_BELOW,
                    payload: {
                        currentQuestionIndex: questionIndex,
                        currentCategoryId: categoryId,
                        questionType: ItemType.NpsScore
                    }
                })
            }
            onSelected={() =>
                dispatch({
                    type: ActionTypes.ITEM_SELECTED,
                    payload: { categoryItem: data }
                })
            }
            onRemove={() =>
                dispatch({
                    type: ActionTypes.REMOVE_QUESTION,
                    payload: {
                        currentCategoryId: categoryId,
                        selectionId: data.id,
                        selectionIndex: questionIndex
                    }
                })
            }
        >
            <Stack direction="column" sx={{ p: 1 }}>
                <FramelessEdit value={data.question.text} size="small" sx={{ pl: 2 }} multiline onChange={(event) => updateNpsScoreText(event.target.value)} />
                <FormControl sx={{ pl: 2 }} style={{ marginTop: 8 }}>
                    <StyledRating
                        name={`nps-score-${index}`}
                        defaultValue={9}
                        max={10}
                        IconContainerComponent={IconContainer}
                        getLabelText={(value: number) => customIcons[value].label}
                        highlightSelectedOnly
                        readOnly
                    />
                </FormControl>
            </Stack>
        </SelectableItem>
    )
}

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconFilled': {
        color: '#767e84',
        opacity: 1
    },
    '& .MuiRating-iconHover': {
        color: '#767e84',
        opacity: 1
    },
    '& .MuiRating-iconEmpty': {
        color: '#767e84',
        opacity: 1
    }
}))

function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props
    return (
        <span
            style={{
                display: 'flex',
                padding: '10px 12px',
                border: '1px solid #63cd91',
                fontSize: '16px',
                marginRight: '5px'
            }}
            {...other}
        >
            {customIcons[value].label}
        </span>
    )
}

const customIcons: {
    [index: string]: { label: string }
} = {
    1: {
        label: '1'
    },
    2: {
        label: '2'
    },
    3: {
        label: '3'
    },
    4: {
        label: '4'
    },
    5: {
        label: '5'
    },
    6: {
        label: '6'
    },
    7: {
        label: '7'
    },
    8: {
        label: '8'
    },
    9: {
        label: '9'
    },
    10: {
        label: '10'
    }
}

export interface Props {
    data: NpsScoreQuestionUiModel
    questionIndex: number
    categoryId: string
    index: number
}
