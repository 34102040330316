// Action Types

import { CategoryItem, CategoryModel, ItemType, MessageEndModel, NextButtonModel, PrevButtonModel, SurveyModel } from '@feeba/types'
import { OptionalPageDividerUiModel } from '../component/OptionalPageDividerComponent'

export enum ActionTypes {
    SURVEY_BODY_UPDATE = 'SURVEY_BODY_UPDATE',
    ITEM_SELECTED = 'ITEM_SELECTED',
    ITEM_UPDATE = 'ITEM_UPDATE',
    // Page Break
    PAGE_BREAK_UPDATE = 'PAGE_BREAK_UPDATE',
    // Add Question
    ADD_QUESTION_ABOVE = 'ADD_QUESTION_ABOVE',
    ADD_QUESTION_BELOW = 'ADD_QUESTION_BELOW',
    REMOVE_QUESTION = 'REMOVE_QUESTION',
    // Category
    ADD_CATEGORY = 'ADD_CATEGORY',
    UPDATE_CATEGORY = 'UPDATE_CATEGORY',
    // Next Prev Button
    NEXT_PREV_BUTTON_UPDATE = 'NEXT_PREV_BUTTON_UPDATE',
    //Update type
    UPDATE_TYPE = 'UPDATE_TYPE',
    TEXTENTRY_UPDATE = 'TEXTENTRY_UPDATE',
    NPS_SCORE_UPDATE = 'NPS_SCORE_UPDATE'
}

export interface SurveyBodyUpdateAction {
    type: ActionTypes.SURVEY_BODY_UPDATE
    payload: {
        survey: SurveyModel
    }
}

export interface PageBreakAction {
    type: ActionTypes.PAGE_BREAK_UPDATE
    payload: {
        currentCategoryId: string
        pageBreakIndex: number
        data: OptionalPageDividerUiModel
    }
}

export interface AddCategoryAction {
    type: ActionTypes.ADD_CATEGORY
    payload: {
        slideInIndex: number
    }
}

export interface AddQuestionAction {
    type: ActionTypes.ADD_QUESTION_ABOVE | ActionTypes.ADD_QUESTION_BELOW
    payload: {
        currentCategoryId: string
        currentQuestionIndex: number
        questionType: ItemType.MultipleChoice | ItemType.TextEntry | ItemType.MatrixTable | ItemType.NpsScore
    }
}

export interface RemoveQuestionItemAction {
    type: ActionTypes.REMOVE_QUESTION
    payload: {
        currentCategoryId: string
        selectionId: string
        selectionIndex: number
    }
}

export interface ItemSelectedAction {
    type: ActionTypes.ITEM_SELECTED | ActionTypes.ITEM_UPDATE
    payload: {
        category?: CategoryModel
        categoryItem?: CategoryItem
        survey?: SurveyModel
    }
}

export interface NextPrevButtonAction {
    type: ActionTypes.NEXT_PREV_BUTTON_UPDATE
    payload: {
        prevButtonText: PrevButtonModel
        nextButtonText: NextButtonModel
    }
}

export interface UpdateType {
    type: ActionTypes.UPDATE_TYPE
    payload: {
        categoryItem: CategoryItem
    }
}

export interface TextEntryUpdate {
    type: ActionTypes.TEXTENTRY_UPDATE
    payload: {
        categoryItem: CategoryItem
    }
}

export interface NpsScoreUpdate {
    type: ActionTypes.NPS_SCORE_UPDATE
    payload: {
        categoryItem: CategoryItem
    }
}

export interface UpdateCagegoryuiModel {
    type: ActionTypes.UPDATE_CATEGORY
    payload: {
        category: CategoryModel
    }
}

// Union Type of All Actions
export type SelectionAction =
    | PageBreakAction
    | SurveyBodyUpdateAction
    | AddCategoryAction
    | AddQuestionAction
    | RemoveQuestionItemAction
    | NextPrevButtonAction
    | UpdateType
    | TextEntryUpdate
    | NpsScoreUpdate
    | ItemSelectedAction
    | UpdateCagegoryuiModel
