import { Box } from '@mui/material'
import { FC } from 'react'
import { TriggerEditor } from './TriggerEditor'

export const TriggersPage: FC<Props> = ({ surveyId }) => {
    return (
        <Box>
            <TriggerEditor surveyId={surveyId} />
        </Box>
    )
}
export interface Props {
    surveyId: string
}
