import { Button, Divider, Grid, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import usePopup from '../../../../popups/notifications/hook'
import { SurveyLanguageUiModel } from '../../Models'
import { createSurveyLanguage, deleteSurveyLanguage, fetchSurvey, fetchSurveyLanguages, updateDefaultSurveyLanguage, upsertSurvey } from '../../RestClient'

import { SurveyModel } from '@feeba/types'
import { ArrowBack } from '@mui/icons-material'
import { DEFAULT_LANG } from '../../../../../utils/Langs'
import { addInitialCategoryBlockIfEmpty } from './Utils'
import { BaseLanguageComponent } from './multilanguage/BaseLanguageComponents'
import { SurveyAddLanguageDialog } from './multilanguage/SurveyAddLanguageDialog'
import { SurveyGeneralList } from './multilanguage/SurveyGeneralList'
import { SurveyMultilanguageCenterComponent } from './multilanguage/SurveyMultilanguageCenterComponent'
import { SurveyMultilanguageMenuComponent } from './multilanguage/SurveyMultilanguageMenuComponent'

export const SurveyMultilanguagePage: FC<Props> = ({ surveyId }) => {
    const { showNotification } = usePopup()
    const [languages, setLanguages] = useState<SurveyLanguageUiModel[] | null>()
    const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)
    const [createLanguageDialogOpen, setCreateLanguageDialogOpen] = useState(false)

    const [surveyToEdit, setSurveyToEdit] = useState<SurveyModel | null>(null)
    const [defaultLanguageSurvey, setDefaultLanguageSurvey] = useState<SurveyModel | null>(null)

    const loadLanguages = () => {
        fetchSurveyLanguages(surveyId)
            .then((result) => {
                setLanguages(result)
            })
            .catch((err) => {
                console.error('fetching', err)
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })
    }

    const loadSurvey = async (lang: string) => {
        const result = await fetchSurvey(surveyId, lang)
        return result
    }

    useEffect(() => {
        loadLanguages()
        loadSurvey(DEFAULT_LANG).then((result) => {
            setDefaultLanguageSurvey(addInitialCategoryBlockIfEmpty(result))
        })
    }, [surveyId])

    const createLanguageAction = (surveyLanguage: SurveyLanguageUiModel[]) => {
        console.log(surveyLanguage)

        createSurveyLanguage(surveyId, surveyLanguage)
            .then((result) => {
                setCreateLanguageDialogOpen(false)
                showNotification({ text: 'Language created', type: 'success' })
                loadLanguages()
            })
            .catch((err) => {
                console.error('fetching', err)
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })
            .finally(() => {
                loadLanguages()
            })
    }

    const deleteSurveyLanguageAction = (langCode: string) => {
        deleteSurveyLanguage(surveyId, langCode)
            .then((result) => {
                showNotification({ text: 'Language deleted', type: 'success' })
                loadLanguages()
            })
            .catch((err) => {
                console.error('fetching', err)
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })
            .finally(() => {
                loadLanguages()
            })
    }

    const choseLanguage = (langCode: string) => {
        setSelectedLanguage(langCode)
        loadSurvey(langCode).then((result) => {
            updateCurrentLanguageSurvey(addInitialCategoryBlockIfEmpty(result))
        })
    }

    const updateCurrentLanguageSurvey = (survey: SurveyModel) => {
        setSurveyToEdit(survey)
    }

    const saveSurveyEdit = () => {
        console.log('saveSurvey', surveyToEdit)
        if (!surveyToEdit) {
            showNotification({ text: 'Survey not found', type: 'error' })

            return
        }
        upsertSurvey(surveyToEdit)
            .then(() => {
                showNotification({ text: 'Survey saved', type: 'success' })
            })
            .catch((err) => {
                console.error('fetching', err)
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })
    }

    const updateSurveyDefaultLanguageAction = (langCode: string, label: string) => {
        updateDefaultSurveyLanguage({ surveyId, langCode, label })
            .then(() => {
                showNotification({
                    text: 'Survey default language updated',
                    type: 'success'
                })
            })
            .catch((err) => {
                console.error('fetching', err)
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })
            .finally(() => {
                loadLanguages()
            })
    }

    return (
        <>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ backgroundColor: 'white' }} padding={2}>
                <Grid item xs={12} sm={4} md={2}>
                    {!selectedLanguage && (
                        <SurveyMultilanguageMenuComponent languages={languages || []} choseLanguage={choseLanguage} setCreateLanguageDialogOpen={setCreateLanguageDialogOpen} />
                    )}

                    {selectedLanguage && (
                        <Button onClick={() => setSelectedLanguage(null)} variant="text" fullWidth={true} style={{ justifyContent: 'left', color: '#32363a' }}>
                            <ArrowBack fontSize="small" style={{ textAlign: 'left' }} />

                            <Typography textAlign={'left'} marginLeft={2}>
                                {languages?.find((el) => el.langCode === selectedLanguage)?.label || ''}
                            </Typography>
                        </Button>
                    )}
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />

                <Grid item xs={12} sm={8} md={10}>
                    {selectedLanguage && surveyToEdit && (
                        <SurveyMultilanguageCenterComponent
                            surveyId={surveyId}
                            survey={surveyToEdit}
                            selectedLanguage={selectedLanguage}
                            defaultLanguageSurvey={defaultLanguageSurvey}
                            selectedLanguagLabel={languages?.find((el) => el.langCode === selectedLanguage)?.label || ''}
                            updateCurrentLanguageSurvey={updateCurrentLanguageSurvey}
                            saveSurveyEdit={saveSurveyEdit}
                        />
                    )}
                    {!selectedLanguage && languages && (
                        <SurveyGeneralList languages={languages} choseLanguage={choseLanguage} deleteSurveyLanguageAction={deleteSurveyLanguageAction} />
                    )}

                    {!selectedLanguage && languages && (
                        <Grid xs={12} sm={5} md={3} style={{ marginTop: '25px' }} item>
                            <BaseLanguageComponent languages={languages || null} updateSurveyDefaultLanguageAction={updateSurveyDefaultLanguageAction} />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <SurveyAddLanguageDialog
                setCreateLanguageDialogOpen={setCreateLanguageDialogOpen}
                createLanguageDialogOpen={createLanguageDialogOpen}
                createLanguageAction={createLanguageAction}
                languages={languages || []}
            />
        </>
    )
}

export interface Props {
    surveyId: string
}
