import { SurveyModel } from '@feeba/types'
import { Email, EngineeringOutlined, Fingerprint, IntegrationInstructions, NoAccounts, NotificationsActive, QrCode2, Share, Summarize, Webhook } from '@mui/icons-material'
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { grey } from '@mui/material/colors'
import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { checkIsActive } from '../../../../../_metronic/helpers'
import { UnlockFeatureTooltip } from '../../../../../components/FeebaToolTip'
import { AnonymousLinkComponent } from './anonymous-link/AnonymousLinkComponent'
import { Push } from './app&push/Push'
import { TriggersPage } from './in-app-triggers/TriggersPage'

export const navLinks = [
    {
        title: 'Distribution summary',
        icon: <Summarize />,
        element: (survey: SurveyModel) => <>Distribution summary</>,
        path: 'dist_summaryy'
    },
    null,
    {
        title: 'Anonymous link',
        element: (survey: SurveyModel) => <AnonymousLinkComponent data={survey.distribution.anonymousLink} />,
        icon: <NoAccounts />,
        path: 'anonymous-link'
    },
    {
        title: 'Emails',
        element: () => <></>,
        icon: <Email />,
        path: 'emails',
        disabled: true
    },
    {
        title: 'Personal links',
        element: () => <></>,
        icon: <Fingerprint />,
        path: 'personal-links',
        disabled: true
    },
    {
        title: 'Social media',
        element: () => <></>,
        icon: <Share />,
        path: 'social-media',
        disabled: true
    },
    {
        title: 'In-App triggers',
        icon: <Webhook />,
        element: (survey: SurveyModel) => <TriggersPage surveyId={survey.id} />,
        path: 'triggers'
    },
    {
        title: 'Inlined survey',
        icon: <IntegrationInstructions />,
        element: (survey: SurveyModel) => <>TBD</>,
        path: 'inlined'
    },
    {
        title: 'Push Notification',
        icon: <NotificationsActive />,
        element: () => <Push />,
        path: 'push',
        disabled: true
    },
    {
        title: 'QR code',
        icon: <QrCode2 />,
        element: () => <></>,
        path: 'qr-code',
        disabled: true
    }
]

export const DistributionSideMenu: FC = () => {
    const { pathname } = useLocation()

    return (
        <Box height="100vh" sx={{ bgcolor: 'white' }}>
            <List sx={{ pt: 0 }}>
                <Divider sx={{ bgcolor: 'black' }} key={-1} />
                {navLinks.map((item, index) => {
                    if (item) {
                        const listItem = (
                            <ListItem key={item.title} disablePadding secondaryAction={item.disabled && <EngineeringOutlined sx={{ color: 'gray' }} fontSize="small" />}>
                                <ListItemButton selected={checkIsActive(pathname, item.path)} disabled={item.disabled}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.title} />
                                </ListItemButton>
                            </ListItem>
                        )
                        return item.disabled ? (
                            <Box key={index} sx={{ bgcolor: grey[200] }}>
                                <UnlockFeatureTooltip origin={item.title} placement="left">
                                    {listItem}
                                </UnlockFeatureTooltip>
                                <Divider sx={{ bgcolor: 'black' }} />
                            </Box>
                        ) : (
                            <Link to={item.path} key={index}>
                                {listItem}
                                <Divider sx={{ bgcolor: 'black' }} />
                            </Link>
                        )
                    } else {
                        return <Divider key={index} />
                    }
                })}
            </List>
        </Box>
    )
}
