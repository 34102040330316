import { AnswerSelectionType, MultipleChoiceQuestionUiModel, RandomizationType } from '@feeba/types'
import { AddRounded, AltRoute, ArrowRight, Edit, MoveDownRounded, QueuePlayNext, RemoveRounded, Shuffle } from '@mui/icons-material'
import { BoxProps, Button, FormControl, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Select, Stack, Switch, TextField, Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { FeebaAccordion, FeebaAccordionDetails, FeebaAccordionSummary } from '../../component/PropertiesAccordion'
import { ActionTypes } from '../state/actions'
import { surveyDispatcher } from '../state/hooks'
import { MultiChoiceRandomizationDialog } from './MultiChoiceRandomizationDialog'
import { generateMockMultichoiceAnswer } from '../Utils'

export const MultiChoiceProperties: FC<Props & BoxProps> = (props) => {
    const [required, setRequired] = useState<boolean>(props.model.required)
    const [choiceCount, setChoiceCount] = useState<number>(props.model.answers.length)
    const [answerType, setAnswerType] = useState<AnswerSelectionType>(props.model.answerType)
    const [randomization, setRandomization] = useState<RandomizationType>(props.model.answerRandomization)

    const [isRandomizerOpen, setIsRandomizerOpen] = useState<boolean>(false)
    const dispatch = surveyDispatcher()

    useEffect(() => {
        let answers = props.model.answers.map((a) => a)
        if (choiceCount < answers.length) {
            // Cut the last element of the array
            answers.splice(choiceCount, answers.length - choiceCount)
        } else {
            for (let i = answers.length; i < choiceCount; i++) {
                answers.push(generateMockMultichoiceAnswer(i + 1))
            }
        }
        dispatch({
            type: ActionTypes.ITEM_UPDATE,
            payload: {
                categoryItem: { ...props.model, answers, required, answerType, answerRandomization: randomization }
            }
        })
    }, [choiceCount, required, answerType, randomization])

    return (
        <Stack direction="column">
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />} id="panel1a-header">
                    <Typography>Answer type</Typography>
                </FeebaAccordionSummary>
                <FeebaAccordionDetails>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={answerType}
                            onChange={(event) => setAnswerType(event.target.value as AnswerSelectionType)}
                        >
                            <FormControlLabel value={AnswerSelectionType.Single} control={<Radio size={'small'} />} label="Allow one answer" />
                            <FormControlLabel value={AnswerSelectionType.Multiple} control={<Radio size={'small'} />} label="Allow multiple answers" />
                        </RadioGroup>
                    </FormControl>
                </FeebaAccordionDetails>
            </FeebaAccordion>
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />} id="panel1a-header">
                    <Typography>Choices</Typography>
                </FeebaAccordionSummary>
                <FeebaAccordionDetails>
                    <IconButton onClick={() => setChoiceCount(choiceCount - 1)}>
                        {' '}
                        <RemoveRounded />{' '}
                    </IconButton>
                    <TextField
                        size="small"
                        InputProps={{ inputProps: { min: 0, max: 25 } }}
                        value={choiceCount}
                        variant="outlined"
                        sx={{ maxWidth: 48 }}
                        onChange={(event) => setChoiceCount(parseInt(event.target.value))}
                    />
                    <IconButton onClick={() => setChoiceCount(choiceCount + 1)}>
                        {' '}
                        <AddRounded />{' '}
                    </IconButton>
                    <Button startIcon={<Edit />} fullWidth style={{ justifyContent: 'flex-start' }} size="small" sx={{ mt: 2 }}>
                        Edit multiple
                    </Button>
                    <FormControlLabel
                        labelPlacement="start"
                        sx={{ mt: 1, pr: 1, width: '100%' }}
                        label={<Typography sx={{ width: '100%' }}>Use suggested choices</Typography>}
                        control={<Switch size="small" />}
                    />
                </FeebaAccordionDetails>
            </FeebaAccordion>
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />}>
                    <Typography>Format</Typography>
                </FeebaAccordionSummary>
                <FeebaAccordionDetails>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        fullWidth
                        size="small"
                        value={1}
                        // onChange={handleChange}
                    >
                        <MenuItem value={0}>List</MenuItem>
                        <MenuItem value={1}>Dropdown</MenuItem>
                        <MenuItem value={2}>Select box</MenuItem>
                    </Select>
                    <Typography sx={{ mt: 2 }} fontSize={'small'}>
                        Alignment
                    </Typography>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        fullWidth
                        size="small"
                        value={0}
                        // onChange={handleChange}
                    >
                        <MenuItem value={0}>Vertical</MenuItem>
                        <MenuItem value={1}>Horizontal</MenuItem>
                        <MenuItem value={2}>Column</MenuItem>
                    </Select>
                    <Button startIcon={<QueuePlayNext />} fullWidth={true} style={{ justifyContent: 'flex-start' }}>
                        Add choice group
                    </Button>
                </FeebaAccordionDetails>
            </FeebaAccordion>
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />} id="panel1a-header">
                    <Typography>Response requirements</Typography>
                </FeebaAccordionSummary>
                <FeebaAccordionDetails>
                    <FormControlLabel
                        labelPlacement="start"
                        sx={{ mt: 1, pr: 1, width: '100%' }}
                        label={<Typography sx={{ width: '100%' }}>Add requirements</Typography>}
                        control={<Switch size="small" checked={required} onChange={(event) => setRequired(event.target.checked)} />}
                    />
                    <FormControlLabel
                        labelPlacement="start"
                        sx={{ mt: 1, pr: 1, width: '100%' }}
                        disabled
                        label={<Typography sx={{ width: '100%' }}>Add validation</Typography>}
                        control={<Switch size="small" />}
                    />
                </FeebaAccordionDetails>
            </FeebaAccordion>
            <FeebaAccordion defaultExpanded>
                <FeebaAccordionSummary expandIcon={<ArrowRight />}>
                    <Typography>Question behavior</Typography>
                </FeebaAccordionSummary>
                <FeebaAccordionDetails>
                    <Button disabled startIcon={<AltRoute />} fullWidth={true} style={{ justifyContent: 'flex-start' }}>
                        Display logic
                    </Button>
                    <Button disabled startIcon={<MoveDownRounded />} fullWidth={true} style={{ justifyContent: 'flex-start' }}>
                        Skip logic
                    </Button>
                    <Button startIcon={<Shuffle />} fullWidth={true} style={{ justifyContent: 'flex-start' }} onClick={() => setIsRandomizerOpen(true)}>
                        Choice randomization
                    </Button>
                </FeebaAccordionDetails>
            </FeebaAccordion>
            {isRandomizerOpen && (
                <MultiChoiceRandomizationDialog
                    model={randomization}
                    open={true}
                    onClose={() => setIsRandomizerOpen(false)}
                    onOptionSelected={(option) => {
                        setRandomization(option)
                        setIsRandomizerOpen(false)
                    }}
                />
            )}
        </Stack>
    )
}

export interface Props {
    model: MultipleChoiceQuestionUiModel
}
